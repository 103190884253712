@tailwind base;
@tailwind components;
@tailwind utilities;
/* @import "./assets/fonts/jost/font1.css";
@import "./assets/fonts/ledger/font2.css";
@import "./assets/fonts/nunito/font3.css"; */
@import "./assets/fonts/cormorent/font-cormorant.css";
@import "./assets/fonts/futura/font-futura.css";
@import "./assets/fonts/nunito/font3.css";
@import "./assets/fonts/cardillac/font4.css";
@import "./assets/fonts/arabic/arabic.css";
@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

.container {
  @apply max-w-full xxl:max-w-[1340px] 3xl:max-w-[1480px];
}

* {
  -webkit-tap-highlight-color: transparent;
}
html {
  @apply overflow-x-hidden;
}
body {
  @apply font-futura overflow-x-hidden;
}
.hero-section {
  /* background-image: url("./assets/images/banner-hero-4.jpg"); */
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 0s 600000s, color 0s 600000s;
}

.new-arrive-content p {
  @apply text-base-18 font-500 mb-5 text-white;
}
p.new-arrive-content {
  @apply text-base-18 font-500 mb-5 text-white;
}

.new-arrive-content {
  @apply text-base-18 font-500 mb-5 text-white;
}

.product-card-content p {
  @apply text-black line-clamp-2 text-center sm:max-w-[80%] mx-auto text-xs sm:text-sm;
}

.product-card-content {
  @apply text-black line-clamp-2 text-center sm:max-w-[80%] mx-auto text-xs sm:text-sm;
}

.btn-theme {
  z-index: 1;
}

.btn-theme::before {
  transition: transfrom 0.3s;
  transform-origin: 0 0;
  transition-timing-function: cubic-bezier(0.5, 1.6, 0.4, 0.7);
  transform: scaleY(0);
}

.btn-theme:hover::before {
  transform: scaleY(1);
}

.btn-theme:disabled::before {
  transform: scaleY(0);
}
a {
  display: inline-block;
}

.page-404-wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.page-404-wrapper .page_404_btn {
  position: absolute;
  bottom: 10%;
}

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

input[type="number"] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

.slick-track {
  @apply !flex;
}

.slider_flex .slick-track {
  display: flex !important;
}

.slider_flex .slick-track .slick-slide {
  height: inherit !important;
}

.slider_flex .slick-track .slick-slide > div,
.slider_flex .slick-track .slick-slide .slider_item,
.slider_flex .slick-track .slick-slide .slider_item > div {
  height: 100% !important;
}

.slide_gap24.slick-slider {
  margin-left: -12px;
  margin-right: -12px;
}

.slide_gap24 .slick-slide {
  margin-left: 12px;
  margin-right: 12px;
}

.slide_gap16.slick-slider {
  margin-left: -8px;
  margin-right: -8px;
}

.slide_gap16 .slick-slide {
  margin-left: 8px;
  margin-right: 8px;
}

.slide_gap12.slick-slider {
  margin-left: -6px;
  margin-right: -6px;
}

.slide_gap12 .slick-slide {
  margin-left: 6px;
  margin-right: 6px;
}
.slide_gap8.slick-slider {
  margin-left: -4px;
  margin-right: -4px;
}

.slide_gap8.slick-slide {
  margin-left: 4px;
  margin-right: 4px;
}

h1 {
  @apply text-3xl leading-1.5 text-dark;
}

h2 {
  @apply text-2xl leading-1.5 text-dark;
}

h3 {
  @apply text-xl leading-1.5 text-dark;
}

h4 {
  @apply text-lg leading-1.5 text-dark;
}

h5 {
  @apply text-base-20 leading-1.5 text-dark;
}

h6 {
  @apply text-base-18 leading-1.5 text-dark;
}

p {
  @apply text-sm leading-1.5 text-gray-dark;
}

label {
  @apply leading-1.5;
}

.section-gap {
  @apply py-[32px] lg:py-[60px] md:py-[40px];
}

.slick-arrow::before {
  @apply text-black;
}

.radio-slider.slick-slider {
  @apply px-6;
}

.radio-slider.slick-slider .slick-arrow.slick-prev {
  @apply left-0;
}

.radio-slider.slick-slider .slick-arrow.slick-next {
  @apply right-0;
}

.hidden-scrollbar::-webkit-scrollbar {
  @apply hidden;
}

.theme-scrollbar::-webkit-scrollbar {
  @apply w-1;
}

.theme-scrollbar::-webkit-scrollbar-track {
  @apply w-1;
}

.theme-scrollbar::-webkit-scrollbar-thumb {
  @apply bg-black;
}

::-webkit-scrollbar {
  @apply w-1;
}

::-webkit-scrollbar-track {
  @apply w-1;
}

::-webkit-scrollbar-thumb {
  @apply bg-black;
}

.custom-otp-input-wrapper input {
  @apply bg-gray-light border border-gray-mid focus:border-black/10 focus:text-black focus:bg-gold/10 text-base text-gray-dark !w-[60px] h-[50px] rounded-sm outline-none;
}

.custom-otp-input-wrapper > div {
  @apply flex items-center justify-center gap-md;
}

input.peer-sorting:checked + label > span {
  @apply border-black before:block;
}

input.peer-sorting:checked + label {
  @apply text-black;
}

.sorting-range {
  @apply h-[6px] bg-[#F6F2EF];
}

.sorting-range .track-1 {
  @apply h-[6px] bg-black;
}

.sorting-range .thumb {
  @apply w-4 h-4 rounded-full bg-white border-[2px] border-black -top-[5px] cursor-pointer;
}

.sorting-range .thumb.active {
  @apply outline-none;
}

.custome_rating span svg {
  height: 50px;
  width: 50px;
}

.custome_rating span svg.text-yellow-700 {
  fill: #fbc02d;
}

.custome_rating span svg.text-blue-gray-500 {
  stroke: #95a9b2;
}

.sub-slider .slick-slider .slick-arrow::before {
  content: "";
}

.sub-slider .slick-slider .slick-slide .product-img-tiny {
  @apply border-[3px] border-white;
}

.sub-slider .slick-slider .slick-slide.slick-current .product-img-tiny {
  @apply border-[3px] border-black;
}

/* .slick-slider.gap-16 .slick-track{
    gap: 16px;
    margin-left: 16px;
    
}

.slick-slider.gap_0 .slick-track{
    gap: 0;
    margin-left: -12px;
    margin-right: -12px;
    
} */

.slick-slider.fixed_slider .slick-track {
  left: 0 !important;
}

.slick-slider.related_slider .slick-track {
  left: 0 !important;
}

.slick-slider.new_collection_slider .slick-track {
  left: 0 !important;
}

.custom_pagination .page-item {
  @apply text-gray-dark;
}

.custom_pagination .page-item.active {
  @apply text-black;
}

.slick-dots {
  @apply -bottom-[30px];
}

@media (min-width: 992px) {
  .filter_part {
    position: unset;
  }
}

@media (max-width: 576px) {
  .slick-slider.fixed_slider .slick-track {
    left: 30px !important;
  }

  .slick-slider.related_slider .slick-track {
    left: 30px !important;
  }

  .slick-slider.new_collection_slider .slick-track {
    left: -5px !important;
  }
}

@media (max-width: 992px) {
  .sub-menu-anime {
    animation: 0.5s ease 0s 1 normal forwards running slideRight;
  }
}

@keyframes slideRight {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }

  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

/* 
.new-arrives-section{
    background-image: url("./assets/images/banner-bg.png");
} */

.location-info-section h5 {
  max-width: 130px;
  overflow-x: hidden;
  overflow-y: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  @apply !font-nunito;
}

.custom-dots .slick-dots {
  display: flex !important;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.custom-dots .slick-dots li {
  width: 6px;
  height: 6px;
  border-radius: 12px;
  background-color: #76777b99;
  display: block;
  padding: 0px;
  margin: 0px;
  transition: all 0.5s ease;
  bottom: 12px;
}

.custom-dots .slick-dots li button {
  height: unset;
  width: unset;
  padding: 0;
}

.custom-dots .slick-dots li button:before {
  display: none;
}

.custom-dots .slick-dots li.slick-active {
  width: 16px;
  background-color: #76777b;
}

[dir="rtl"] .slick-slide {
  @apply !float-none;
}

.fancy-heading-shape {
  position: relative;
  /* background-color: #ffffff; */
}

.fancy-heading-shape::before {
  content: url("./assets/images/fancy-text-updated.svg");
  position: absolute;
  top: 58%;
  left: 0;
  transform: translate(-50%, -50%);
  z-index: -1;
}

body[dir="rtl"] .fancy-heading-shape::before {
  content: url("./assets/images/fancy-text-updated-rtl.svg");
  right: 0;
  left: unset;
  transform: translate(50%, -50%);
}

@media (max-width: 768px) {
  .fancy-heading-shape::before {
    content: url("./assets/images/fancy-text-sm-updated.svg");
  }
  body[dir="rtl"] .fancy-heading-shape::before {
    content: url("./assets/images/fancy-text-sm-updated-rtl.svg");
    right: 0;
    left: unset;
    transform: translate(50%, -50%);
  }
}

body[dir="rtl"],
body[dir="rtl"] .font-cormorant,
body[dir="rtl"] .font-futura {
  @apply !font-arabic;
}

.legal-docs h4 {
  @apply text-lg mb-1;
}
.legal-docs p {
  @apply text-base font-400 mb-3 text-dark;
}

.legal-docs h4 strong {
  @apply font-600 font-cormorant rtl:font-500;
}
.legal-docs ul {
  @apply mb-3;
}
.legal-docs ul li {
  @apply relative ps-3 mb-2 block text-base font-400 before:w-1 before:h-1 before:bg-dark before:absolute before:top-2.5 before:start-0 before:rounded-full;
}
/* slider  */
.product-details-slider.slick-slider,
.product-details-slider.slick-slider .slick-list,
.product-details-slider.slick-slider .slick-list .slick-track,
.product-details-slider.slick-slider .slick-list .slick-track .slick-slide,
.product-details-slider.slick-slider
  .slick-list
  .slick-track
  .slick-slide
  > div,
.product-details-slider.slick-slider
  .slick-list
  .slick-track
  .slick-slide
  > div
  .product-main-img {
  @apply h-full;
}

.product-details-slider.slick-slider .slick-list {
  @apply h-full;
}

.slick-slide {
  z-index: 990;
}

.slick-active.slick-current {
  z-index: 991;
}

.gm-ui-hover-effect {
  top: 0 !important;
  right: 0 !important;
  width: auto !important;
  height: auto !important;

  span {
    font-size: 20px !important;
    width: 20px !important;
    height: 20px !important;
  }
}