@font-face {
    font-family: 'Cormorant Garamond';
    src: url('CormorantGaramond-Bold.woff2') format('woff2'),
        url('CormorantGaramond-Bold.woff') format('woff'),
        url('CormorantGaramond-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cormorant Garamond';
    src: url('CormorantGaramond-Regular.woff2') format('woff2'),
        url('CormorantGaramond-Regular.woff') format('woff'),
        url('CormorantGaramond-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cormorant Garamond';
    src: url('CormorantGaramond-Light.woff2') format('woff2'),
        url('CormorantGaramond-Light.woff') format('woff'),
        url('CormorantGaramond-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cormorant Garamond';
    src: url('CormorantGaramond-SemiBold.woff2') format('woff2'),
        url('CormorantGaramond-SemiBold.woff') format('woff'),
        url('CormorantGaramond-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cormorant Garamond';
    src: url('CormorantGaramond-Medium.woff2') format('woff2'),
        url('CormorantGaramond-Medium.woff') format('woff'),
        url('CormorantGaramond-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

