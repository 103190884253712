@font-face {
    font-family: 'Cardillac';
    src: url('Cardillac-Bold.eot');
    src: local('Cardillac Bold'), local('Cardillac-Bold'),
        url('Cardillac-Bold.eot?#iefix') format('embedded-opentype'),
        url('Cardillac-Bold.woff2') format('woff2'),
        url('Cardillac-Bold.woff') format('woff'),
        url('Cardillac-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Cardillac';
    src: url('Cardillac-ExtraLight.eot');
    src: local('Cardillac ExtraLight'), local('Cardillac-ExtraLight'),
        url('Cardillac-ExtraLight.eot?#iefix') format('embedded-opentype'),
        url('Cardillac-ExtraLight.woff2') format('woff2'),
        url('Cardillac-ExtraLight.woff') format('woff'),
        url('Cardillac-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Cardillac';
    src: url('Cardillac-LightItalic.eot');
    src: local('Cardillac Light Italic'), local('Cardillac-LightItalic'),
        url('Cardillac-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('Cardillac-LightItalic.woff2') format('woff2'),
        url('Cardillac-LightItalic.woff') format('woff'),
        url('Cardillac-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Cardillac';
    src: url('Cardillac-BoldItalic.eot');
    src: local('Cardillac Bold Italic'), local('Cardillac-BoldItalic'),
        url('Cardillac-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('Cardillac-BoldItalic.woff2') format('woff2'),
        url('Cardillac-BoldItalic.woff') format('woff'),
        url('Cardillac-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Cardillac';
    src: url('Cardillac-Italic.eot');
    src: local('Cardillac Italic'), local('Cardillac-Italic'),
        url('Cardillac-Italic.eot?#iefix') format('embedded-opentype'),
        url('Cardillac-Italic.woff2') format('woff2'),
        url('Cardillac-Italic.woff') format('woff'),
        url('Cardillac-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Cardillac';
    src: url('Cardillac-Black.eot');
    src: local('Cardillac Black'), local('Cardillac-Black'),
        url('Cardillac-Black.eot?#iefix') format('embedded-opentype'),
        url('Cardillac-Black.woff2') format('woff2'),
        url('Cardillac-Black.woff') format('woff'),
        url('Cardillac-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Cardillac';
    src: url('Cardillac-MediumItalic.eot');
    src: local('Cardillac Medium Italic'), local('Cardillac-MediumItalic'),
        url('Cardillac-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('Cardillac-MediumItalic.woff2') format('woff2'),
        url('Cardillac-MediumItalic.woff') format('woff'),
        url('Cardillac-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Cardillac';
    src: url('Cardillac-Medium.eot');
    src: local('Cardillac Medium'), local('Cardillac-Medium'),
        url('Cardillac-Medium.eot?#iefix') format('embedded-opentype'),
        url('Cardillac-Medium.woff2') format('woff2'),
        url('Cardillac-Medium.woff') format('woff'),
        url('Cardillac-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Cardillac';
    src: url('Cardillac-Regular.eot');
    src: local('Cardillac Regular'), local('Cardillac-Regular'),
        url('Cardillac-Regular.eot?#iefix') format('embedded-opentype'),
        url('Cardillac-Regular.woff2') format('woff2'),
        url('Cardillac-Regular.woff') format('woff'),
        url('Cardillac-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Cardillac';
    src: url('Cardillac-ExtraLightItalic.eot');
    src: local('Cardillac ExtraLight Italic'), local('Cardillac-ExtraLightItalic'),
        url('Cardillac-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
        url('Cardillac-ExtraLightItalic.woff2') format('woff2'),
        url('Cardillac-ExtraLightItalic.woff') format('woff'),
        url('Cardillac-ExtraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Cardillac';
    src: url('Cardillac-Light.eot');
    src: local('Cardillac Light'), local('Cardillac-Light'),
        url('Cardillac-Light.eot?#iefix') format('embedded-opentype'),
        url('Cardillac-Light.woff2') format('woff2'),
        url('Cardillac-Light.woff') format('woff'),
        url('Cardillac-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Cardillac';
    src: url('Cardillac-BlackItalic.eot');
    src: local('Cardillac Black Italic'), local('Cardillac-BlackItalic'),
        url('Cardillac-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('Cardillac-BlackItalic.woff2') format('woff2'),
        url('Cardillac-BlackItalic.woff') format('woff'),
        url('Cardillac-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Cardillac';
    src: url('Cardillac-ExtraBoldItalic.eot');
    src: local('Cardillac ExtraBold Italic'), local('Cardillac-ExtraBoldItalic'),
        url('Cardillac-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('Cardillac-ExtraBoldItalic.woff2') format('woff2'),
        url('Cardillac-ExtraBoldItalic.woff') format('woff'),
        url('Cardillac-ExtraBoldItalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Cardillac';
    src: url('Cardillac-ExtraBold.eot');
    src: local('Cardillac ExtraBold'), local('Cardillac-ExtraBold'),
        url('Cardillac-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('Cardillac-ExtraBold.woff2') format('woff2'),
        url('Cardillac-ExtraBold.woff') format('woff'),
        url('Cardillac-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

