@font-face {
    font-family: 'Futura Bk BT';
    src: url('../futura/FuturaBT-Book.woff2') format('woff2'),
        url('../futura/FuturaBT-Book.woff') format('woff'),
        url('../futura/FuturaBT-Book.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

