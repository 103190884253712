@font-face {
    font-family: 'Droid Arabic Kufi';
    src: url('DroidArabicKufi-Bold.woff2') format('woff2'),
        url('DroidArabicKufi-Bold.woff') format('woff'),
        url('DroidArabicKufi-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Droid Arabic Kufi';
    src: url('DroidArabicKufi.woff2') format('woff2'),
        url('DroidArabicKufi.woff') format('woff'),
        url('DroidArabicKufi.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

